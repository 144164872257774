import request from "./request.js";
import baseUrl from "./config.js"
// import qs from "qs";

// 示例
// export function login(params) {
// 	// let formData = new FormData(); // 创建form对象
// 	// formData.append('presentFormInfoVO', params);
// 	return request({
// 		url: baseUrl + "/spider/web/login",
// 		method: "post",
// 		data: params
// 	});
// 	//其实，也不一定就是params，也可以是 query 还有 data 的呀！
// 	//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
// 	//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。
// }

// 注册
export function register(params) {
	return request({
		url: baseUrl + "/article/user/register",
		method: "post",
		data: params
	});
}

// 完善用户信息
export function editUser(params) {
	return request({
		url: baseUrl + "/article/user/editUser",
		method: "post",
		data: params
	});
}

// 发送验证码
export function sendCode(params) {
	return request({
		url: baseUrl + `/article/user/register/sendCode/${params}`,
		method: "get",
	});
}
export function sendCodeLogin(params) {
	return request({
		url: baseUrl + `/article/user/login/sendCode/${params}`,
		method: "get",
	});
}
export function sendCodeChangePassword(params) {
	return request({
		url: baseUrl + `/article/user/changePassword/sendCode/${params}`,
		method: "get",
	});
}

// 修改密码
export function changePassword(params) {
	return request({
		url: baseUrl + `/article/user/changePassword`,
		method: "post",
		data: params
	});
}

// 获取医院、机构列表
export function hospitalList(params) {
	return request({
		url: baseUrl + `/article/hospital/getHospitalName`,
		method: "get",
		params: params
	});
}

// 校验验证码
export function verify(params) {
	return request({
		url: baseUrl + `/article/user/verify`,
		method: "post",
		data: params
	});
}

// 登录
export function login(params) {
	return request({
		url: baseUrl + "/article/user/login",
		method: "post",
		data: params
	});
}

// 注册 
export function regist(params) {
	return request({
		url: baseUrl + "/article/user/regist",
		method: "post",
		data: params
	});
}

// 获取当前登录的用户信息   
export function getUser(params) {
	return request({
		url: baseUrl + `/article/user/getUser`,
		method: "get",
	});
}

// 获取用户投稿数据
export function myStatistics(params) {
	return request({
		url: baseUrl + `/article/user/myStatistics`,
		method: "get",
		params: params
	});
}

// 获取用户投稿记录列表
export function myDocument(params) {
	return request({
		url: baseUrl + `/article/document/myDocument`,
		method: "get",
		params: params
	});
}

// 保存或修改
export function saveOrUpdate(params) {
	return request({
		url: baseUrl + "/article/document/saveOrUpdate",
		method: "post",
		data: params
	});
}

// 删除文档
export function delDocument(params) {
	return request({
		url: baseUrl + "/article/document/delDocument",
		method: "post",
		data: params
	});
}

// 首页获取logo banner 流程图   
export function getConfig(params) {
	return request({
		url: `${baseUrl}/article/home/getConfig`,
		method: "get",
		params: params
	});
}

// 首页获取项目介绍
export function getProject(params) {
	return request({
		url: `${baseUrl}/article/home/project`,
		method: "get",
		params: params
	});
}

//  项目动态 新闻
export function news(params) {
	return request({
		url: `${baseUrl}/article/home/news`,
		method: "get",
		params: params
	});
}

// 动态/新闻详情

export function articleNews(params) {
	return request({
		url: `${baseUrl}/article/news/${params}`,
		method: "get",
	});
}

// 优秀稿件   
export function excellentDocument(params) {
	return request({
		url: `${baseUrl}/article/home/excellentDocument`,
		method: "get",
		params: params
	});
}

// 优秀稿件   
export function Contact(params) {
	return request({
		url: `${baseUrl}/article/contact/getContact`,
		method: "get",
		params: params
	});
}

// 项目介绍组件
export function ProjectPage(params) {
	return request({
		url: `${baseUrl}/article/project/getProject`,
		method: "get",
		params: params
	});
}

// 获取入围作品   
export function documentList(params) {
	return request({
		url: `${baseUrl}/article/document/list`,
		method: "get",
		params: params
	});
}


// 专家评委会数据调用
export function getEvaluation(params) {
	return request({
		url: `${baseUrl}/article/evaluation/getConfig`,
		method: "get",
		params: params
	});
}

// 获取欢迎辞视频
export function getWelcomeVedio(params) {
	return request({
		url: `${baseUrl}/article/welcome/getVedio`,
		method: "get",
		params: params
	});
}

// 联系我们提交意见建议
export function contactUs(params) {
	return request({
		url: `${baseUrl}/article/contact/contactUs`,
		method: "get",
		params: params
	});
}

// 校验文件
export function viewDocument(params) {
	return request({
		url: `${baseUrl}/article/document/viewDocument`,
		method: "get",
		params: params
	});
}

// 领域列表 /article/area/list
export function arealist(params) {
	return request({
		url: `${baseUrl}/article/area/list`,
		method: "get",
		params: params
	});
}
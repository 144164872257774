import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue'),
		meta: {
			title: ''
		}
	}, {
		path: '/Intr',
		name: 'Intr',
		component: () => import('../views/Introduction/Introduction'),
		children: [{
			path: '',
			name: 'introduce',
			component: () => import('../views/Introduction/introduce'),
			meta: {
				title: '项目介绍'
			}
		}, {
			path: 'flowPath',
			name: 'flowPath',
			component: () => import('../views/Introduction/flowPath'),
			meta: {
				title: '参与流程'
			}
		}, {
			path: 'condition',
			name: 'condition',
			component: () => import('../views/Introduction/condition'),
			meta: {
				title: '投稿要求'
			}
		}, {
			path: 'statement',
			name: 'statement',
			component: () => import('../views/Introduction/statement'),
			meta: {
				title: '作品原创及授权声明'
			}
		}]
	}, {
		path: '/contribute',
		name: 'contribute',
		component: () => import('../views/contribute/contribute'),
		meta: {
			title: '作品专区'
		}
	}, {
		path: '/ContentDetails',
		name: 'ContentDetails',
		component: () => import('../views/ContentDetails/ContentDetails'),
		meta: {
			title: '新闻中心'
		}
	}, {
		path: '/newsCenter',
		name: 'newsCenter',
		component: () => import('../views/newsCenter/newsCenter'),
		meta: {
			title: '新闻中心'
		}
	}, {
		path: '/ContactUs',
		name: 'ContactUs',
		component: () => import('../views/ContactUs/ContactUs'),
		meta: {
			title: '联系我们'
		}
	}, {
		path: '/UserCenter',
		name: 'UserCenter',
		component: () => import('../views/UserCenter/UserCenter'),
		meta: {
			title: '参与投稿'
		}
	}, {
		path: '/Welcome',
		name: 'Welcome',
		component: () => import('../views/Welcome/Welcome'),
		meta: {
			title: '欢迎辞'
		}
	}, {
		path: '/Judges',
		name: 'Judges',
		component: () => import('../views/Judges/Judges'),
		meta: {
			title: '专家评委会'
		}
	},

	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('../components/NotFound.vue'),
		meta: {
			title: '404'
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
	// 让页面回到顶部
	document.documentElement.scrollTop = 0
	// 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
	if (to.name != 'nopath') {
		if (to.meta.title) {
			document.title = `启航计划-${to.meta.title}`
		} else {
			document.title = `启航计划`
		}
		next();
	}

})

export default router
<template>
	<div id="webHander" :class="scroll?'bac':''">
		<!-- 页头 -->
		<div class="handCont">
			<img src="../assets/logo.png" alt="" class="logo">
			<div class="main">
				<ul>
					<router-link exact :to="{path:'/'}" tag="li"><a>首页</a></router-link>
					<!-- <router-link :to="{path:'/Welcome'}" tag="li"><a>欢迎辞</a></router-link> -->
					<router-link :to="{path:'/Intr'}" tag="li"><a>项目介绍</a></router-link>
					<router-link :to="{path:'/contribute'}" tag="li"><a>作品专区</a></router-link>
					<!-- <router-link :to="{path:'/newsCenter'}" tag="li"><a>新闻中心</a></router-link> -->
					<!-- <router-link :to="{path:'/Judges'}" tag="li"><a>专家评委会</a></router-link> -->
					<router-link :to="{path:'/ContactUs'}" tag="li"><a>联系我们</a></router-link>
					<router-link class="red" :to="{path:'/UserCenter'}" tag="li"><a>参与投稿</a></router-link>
				</ul>
				<div class="inputSearth" @click="$router.push({path:'/contribute',query:{type:'searth'}})">
					<i slot="prepend" class="el-icon-search"></i>
					优秀征文查询
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getConfig, // 首页获取logo banner 流程图  
	} from '@/request/api.js';
	export default {
		name: 'webHander',
		components: {},
		data() {
			return {
				logo: '',
				scroll: false,
				input: ''
			}
		},
		created() {
			this.getConfigFun();
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll, true);
		},
		methods: {
			// logo
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.logo = res.data.logoPath;
					}
				})
			},

			handleScroll(e) {
				const winHeight = e.target.scrollTop || document.documentElement.scrollTop;
				// console.log(winHeight)
				if (winHeight > 1) {
					this.scroll = true;
				} else {
					this.scroll = false;
				}
			}

		}
	}
</script>

<style scoped lang="scss">
	.bac {
		background-color: #fff;
		box-shadow: 5px 10px 20px rgba(0, 0, 0, .1);

		.inputSearth {
			box-shadow: 5px 5px 10px rgba(0, 0, 0, .1);
			height: 40px;
			display: flex;
			flex-direction: row;
			justify-content: start;
			align-items: center;
			padding: 0 20px;

		}
	}

	#webHander {
		min-width: 1200px;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		height: 80px;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		transition: all .3s;
		z-index: 100;

		.handCont {
			width: 1200px;
			margin: auto;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;


			.logo {
				height: 80px;
			}

			.main {
				display: flex;
				flex-direction: row;
				align-items: center;

				ul {
					flex: 1;
					display: flex;
					flex-direction: row;

					li {
						margin-left: 30px;
						font-size: 16px;
						color: #333;
						position: relative;
						padding: 8px 0;
						cursor: pointer;

						&:before {
							position: absolute;
							content: '';
							left: 50%;
							transform: translateX(-50%);
							bottom: 0;
							height: 3px;
							border-radius: 2px;
							width: 0;
							background-color: rgba(203, 50, 44, .8);
							transition: all .3s;
						}

						&:hover {
							* {
								color: rgb(203, 50, 44);
							}
						}
					}

					.active:before {
						width: 100%;
					}



					.active,
					.red {
						* {
							color: rgb(203, 50, 44);
							text-shadow: 2px 2px 5px rgba(0, 0, 0, .1);
						}
					}
				}

				::v-deep .inputSearth {
					transition: all .5s;
					width: 200px;
					margin-left: 40px;
					border: none;
					border-radius: 20px;
					overflow: hidden;
					box-shadow: 5px 5px 10px rgba(0, 0, 0, .1);
					height: 40px;
					display: flex;
					flex-direction: row;
					justify-content: start;
					align-items: center;
					padding: 0 20px;
					cursor: pointer;
					background-color: #fff;

					* {
						cursor: pointer;
					}

					i {
						margin-right: 10px;
					}
				}
			}
		}
	}
</style>